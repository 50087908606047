<template>
<div class="description__text">
  <div>
    <img src="assets/img/home/logo.svg" class="description__text-logo">
  </div>
  <description-text-box-component
      :title="$t('home.description.boxes.box_1.title')"
      :body="$t('home.description.boxes.box_1.body')"
  ></description-text-box-component>
  <description-text-box-component
      :title="$t('home.description.boxes.box_2.title')"
      :body="$t('home.description.boxes.box_2.body')"
  ></description-text-box-component>
  <description-text-box-component
      :title="$t('home.description.boxes.box_3.title')"
      :body="$t('home.description.boxes.box_3.body')"
  ></description-text-box-component>
  <description-text-box-component
      :title="$t('home.description.boxes.box_4.title')"
      :body="$t('home.description.boxes.box_4.body')"
  ></description-text-box-component>
</div>
</template>

<script>
import DescriptionTextBoxComponent from "@/components/home/DescriptionTextBoxComponent.vue";

export default {
  name: "DescriptionTextComponent",
  components: {DescriptionTextBoxComponent}
}
</script>

<style scoped>

</style>