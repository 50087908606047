<template>
  <div class="how-it-works">
    <div class="how-it-works__container">
      <div class="how-it-works__wrapper">
        <div class="how-it-works__title">How it works?</div>
        <div>
          <how-it-works-boxes-component></how-it-works-boxes-component>
        </div>
        <div class="how-it-works__header-1">Our Achievements</div>
        <div class="how-it-works__header-2">Brief description of the idea, assumptions and purpose of the portal</div>
        <div>
          <how-it-works-values-component></how-it-works-values-component>
        </div>
      </div>
    </div>
    <div class="how-it-works--background"></div>
  </div>
</template>

<script>
import HowItWorksBoxesComponent from "@/components/home/HowItWorksBoxesComponent.vue";
import HowItWorksValuesComponent from "@/components/home/HowItWorksValuesComponent.vue";

export default {
  name: "HowItWorksComponent",
  components: {HowItWorksValuesComponent, HowItWorksBoxesComponent}
}
</script>

<style scoped>

</style>