<template>
  <div class="offer offer__container">
    <div class="offer__data">
      <div class="offer__data_header">
        <span class="offer__data-title">{{ title }}</span>
      </div>
      <div class="offer__data_content">
        <slot name="content"></slot>
      </div>
      <div class="offer__data_button">
        <button>{{ $t('buttons.view_more')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferExtraContainer",
  props: ["title"]
}
</script>

<style scoped>

</style>