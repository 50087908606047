<template>
  <div class="home__offer" v-if="show">
    <div class="home__offer-box">
      <div class="home__offer-data">
        <div class="home__offer-data-name">
          <div class="home__offer-data--label">Offer of the day</div>
          <div class="home__offer-data--car">{{ getOfferOfDay?.mark }} {{ getOfferOfDay?.model }}</div>
        </div>
        <div class="home__offer-data-price">
          <div class="home__offer-price--current">{{ getOfferOfDay?.price }} zł</div>
          <div class="home__offer-price--old" v-if="getOfferOfDay?.isDiscounted == true">
            {{ getOfferOfDay?.priceBeforeDiscount }} zł
          </div>
        </div>
      </div>
      <div>
        <img :src="getOfferOfDay?.carImageSets[0]" alt="offer_of_the_day">
      </div>
      <div class="home__offer-action">
        <router-link :to="{name:'Offer',params:{id:getOfferOfDay?.announcementId}}"
                     class="home__offer-action--button">
          See more
        </router-link>
      </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HomeOfferOfTheDay",
  data() {
    return {
      show: false,
      offer_of_the_day: require('../../assets/images/home/Offer.png')
    }
  },
  mounted() {
    this.setOfferOfDay();
    setTimeout(() => {
      this.show = true;
    }, 750);
  },
  methods: {
    ...mapActions({
      setOfferOfDay: "offer/setOfferOfTheDay"
    })
  },
  computed: {
    ...mapGetters({
      getOfferOfDay: "offer/getOfferOfTheDay"
    }),
  }
}
</script>

<style scoped>

</style>