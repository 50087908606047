<template>
  <div class="searcher__filter" v-bind:style="{ backgroundImage: 'url(' + banner + ')' }">
    <div class="searcher__filter-container">
      <div class="searcher__filter-items searcher__filter-items--car">
        <div class="searcher__filter-item-box">
          <div class="searcher__filter-item-box" style="position: relative">
            <input class="searcher__filter-search" :placeholder="$t('search.filter.search')" v-model="params.search">
            <img class="searcher__filter-search--icon" :src="search" alt="search">
            <search-icon></search-icon>
            <div class="searcher__filter-search--button" @click="onChange">{{$t('search.go')}}</div>
          </div>
<!--          <div class="searcher__filter-item-box">-->
<!--            <v-select :options="personal" v-model="params.personal" @update:modelValue="onChange()" label="label"-->
<!--                      class="searcher__filter-select" :placeholder="$t('search.filter.personal')"></v-select>-->
<!--            <v-select :options="features" v-model="params.features" @update:modelValue="onChange()" label="label"-->
<!--                      class="searcher__filter-select" :placeholder="$t('search.filter.features')"></v-select>-->
<!--          </div>-->
        </div>
        <div class="searcher__filter-item-box">
          <v-select :options="body_type"  v-model="params.body_type" :reduce="(option) => option.value" @update:modelValue="onChange()" label="label"
                    class="searcher__filter-select" :placeholder="$t('search.filter.body_type')"></v-select>
          <v-select :options="gearbox" v-model="params.gearbox" :reduce="(option) => option.value"  @update:modelValue="onChange()" label="label"
                    class="searcher__filter-select" :placeholder="$t('search.filter.gearbox')"></v-select>
          <v-select :options="fuel" v-model="params.fuel" :reduce="(option) => option.value"  @update:modelValue="onChange()" label="label"
                    class="searcher__filter-select" :placeholder="$t('search.filter.fuel')" ></v-select>
          <v-select :options="drive" v-model="params.drive" :reduce="(option) => option.value" @update:modelValue="onChange()" label="label"
                    class="searcher__filter-select" :placeholder="$t('search.filter.drive')"></v-select>
        </div>
      </div>
      <div class="searcher__filter-items searcher__filter-items--price">
        <div class="searcher__filter-items--price-box">
          <searcher-filter-slider v-model="price" :context="$t('search.filter.price')" type="price" max="350" :interval="1" units="k"></searcher-filter-slider>
          <searcher-filter-slider :context="$t('search.filter.loan')" type="loan"></searcher-filter-slider>
        </div>
      </div>
      <div class="searcher__filter-items searcher__filter-items searcher__filter-items-checkboxes"
           :class="{ active: isActive }">
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="brand_new" v-model="params.brand_new" @change="onChange">
          <label for="brand_new">{{ $t('search.filter.brand_new') }}</label>
        </div>
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="used_cars"  v-model="params.used_cars" @change="onChange">
          <label for="used_cars">{{ $t('search.filter.used_cars') }}</label>
        </div>
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="special_offer" v-model="params.special_offer" @change="onChange">
          <label for="special_offer">{{ $t('search.filter.special_offer') }}</label>
        </div>
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="certified" v-model="params.certified" @change="onChange">
          <label for="certified">{{ $t('search.filter.certified') }}</label>
        </div>
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="sale" v-model="params.sale" @change="onChange">
          <label for="sale">{{ $t('search.filter.sale') }}</label>
        </div>
        <div class="searcher__filter-items-checkbox">
          <input type="checkbox" id="invoice" v-model="params.invoice" @change="onChange">
          <label for="invoice">{{ $t('search.filter.invoice') }}</label>
        </div>
      </div>
      <div class="searcher__filter-item--search">
        <button @click="isActive = !isActive">{{ $t('search.show_results') }} <span>3112</span></button>
      </div>
      <div class="searcher__filter-item--show-filters">
        <button @click="isActive = !isActive">{{ $t('search.show_more_filters') }} <img :src="showMore" alt=""></button>
      </div>
    </div>

  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SearcherFilterSlider from "./SearcherFilterSlider";
import SearchIcon from "../svg/SearchIcon";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SearcherFilter",
  components: {SearchIcon, SearcherFilterSlider, vSelect},

  data() {
    return {
      banner: require('../../assets/images/searcher/Banner.png'),
      search: require('../../assets/images/searcher/Search.svg'),
      showMore: require('../../assets/images/searcher/ShowMore.svg'),
      body_type: [
        {
          label: this.$t('search.label.Kombi'),
          value: "Kombi"
        },
        {
          label: this.$t('search.label.SUV'),
          value: "SUV"
        },
        {
          label: this.$t('search.label.Sport'),
          value: "Sport"
        },
        {
          label: this.$t('search.label.Sedan'),
          value: "Sedan"
        },
        {
          label: this.$t('search.label.Hatchback'),
          value: "Hatchback"
        },
        {
          label: this.$t('search.label.City'),
          value: "City"
        },
        // 'Kombi','SUV','Sport','Sedan','Hatchback', 'City'
      ],
      gearbox: [
        {
          label: this.$t('search.label.Manualna skrzynia biegów'),
          value: "Manualna skrzynia biegów"
        },
        {
          label: this.$t('search.label.Automatyczna skrzynia biegów'),
          value: "Automatyczna skrzynia biegów"
        },
        {
          label: this.$t('search.label.Napęd bezpośredni, bez skrzyni biegów'),
          value: "Napęd bezpośredni, bez skrzyni biegów"
        },
        // 'Manualna skrzynia biegów', 'Automatyczna skrzynia biegów','Napęd bezpośredni, bez skrzyni biegów'
      ],
      personal: [
        'Yes', 'No'
      ],
      fuel: [
        {
          label: this.$t('search.label.Benzyna'),
          value: "Benzyna"
        },
        {
          label: this.$t('search.label.Diesel'),
          value: "Diesel"
        },
        {
          label: this.$t('search.label.Elektryczny'),
          value: "Elektryczny"
        },
        // 'Benzyna', 'Diesel', 'Elektryczny'
      ],
      features: [
        'ACC',
        'Safe brake'
      ],
      drive: [
        {
          label: this.$t('search.label.Napęd przedni'),
          value: "Napęd przedni"
        },
        {
          label: this.$t('search.label.Napęd tylny'),
          value: "Napęd tylny"
        },
        {
          label: this.$t('search.label.Dołączany napęd na 4 koła'),
          value: "Dołączany napęd na 4 koła"
        },
        {
          label: this.$t('search.label.Stały napęd na 4 koła'),
          value: "Stały napęd na 4 koła"
        },
        // 'Napęd przedni', 'Napęd tylny', 'Stały napęd na 4 koła'
      ],
      isActive: false,
      // params: {}
    }
  },
  mounted() {
    this.loadParams(this.$route.query)
  },
  computed:{
    ...mapGetters({
      params: 'searcher/getParams'
    }),
    price(){
      return [this.params.min_price ?? null, this.params.max_price ?? null]
    }
  },
  methods: {
    ...mapActions({
      setParams:'searcher/setParams',
      loadParams : 'searcher/loadParams',
    }),
    onChange() {
      //console.log('update')
      this.setParams(this.params)
    },

  }
}
</script>

<style scoped>

</style>