<template>
<div class="how-it-works-box">
  <div class="how-it-works-box__logo">
<!--    <img class="home-it-works-box__logo&#45;&#45;img" :src="url">-->
    <slot name="icon"></slot>
  </div>
  <div class="how-it-works-box__content">
    <div class="content__number">{{number}}</div>
    <div class="content__text">{{text}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "HowItWorksBoxComponent",
  props: ['url', 'number', 'text']
}
</script>

<style scoped>

</style>