<template>
  <div class="offer offer__container">
    <div class="offer__data">
      <div class="offer__data_header">
        <img
            :src="icon"
            :alt="title"
        />
        <span>{{ title }}</span>
      </div>
      <div class="offer_data_content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OfferDataContainer",
  props: ["icon", "title"]
}
</script>

<style scoped>

</style>