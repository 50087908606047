<template>
  <div class="last-seen__content">
    <div class="last-seen__content__wrapper">
      <div class="header">
        <button class="like">
          <img src="/assets/img/search/last-seen/heart_full.svg">
        </button>
        <img :src="car?.imageUrl" >
      </div>
      <div class="links">
        <div class="link">
          <img src="/assets/img/offer/similar-car/menu_stars.svg">
          <a href="">Brand New</a>
        </div>
        <div class="link">
          <img src="/assets/img/offer/similar-car/menu_dolar.svg">
          <a href="">Invoice</a>
        </div>
        <div class="link">
          <img src="/assets/img/offer/similar-car/menu_cert.svg">
          <a href="">Certified</a>
        </div>
      </div>
      <div class="info">
        <div class="info--left">
          <div class="title">{{car?.mark}} {{car?.model}}</div>
          <div>{{ car?.type.replace(car?.model,'') }}</div>
          <div>{{car?.manufacturingYear}} year / {{car?.mileage}} km</div>
        </div>
        <div class="info--right">
          <div class="title">{{car?.price}}zł gross</div>
          <div>500zł gross/mth</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastSeenBoxComponent",
  props:['car']
}
</script>

<style scoped>

</style>