<template>
  <div class="offer__specification_items">
    <div class="offer__specification_item" v-for="item in items" :key="item.label">
      <offer-specification-item
          :icon="item.icon"
          :label="item.label"
          :value="item.value"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OfferSpecificationItem from "./OfferSpecificationItem";

export default {
  name: "OfferSpecification",
  components: {OfferSpecificationItem},
  computed: {
    ...mapGetters({
      offer: 'offer/getOffer'
    }),
    items: function (){
      return[
        {
          label: this.$t('offer.specification.body_type'),
          icon: require('../../assets/images/specifications/body_type.svg'),
          value: this.offer?.data?.DetailsBodyFeatures?.TYPTXTBodyCo1Cd2
        },
        {
          label: this.$t('offer.specification.fuel_type'),
          icon: require('../../assets/images/specifications/fuel_type.svg'),
          value: this.offer?.data?.DetailsMain?.TYPTXTFuelTypeCd2
        },
        {
          label: this.$t('offer.specification.engine'),
          icon: require('../../assets/images/specifications/engine.svg'),
          value: this.offer?.data?.DetailsMain?.TYPCapTech
        },
        {
          label: this.$t('offer.specification.engine_power'),
          icon: require('../../assets/images/specifications/engine_power.svg'),
          value: this.offer?.data?.Main?.TYPHP
        },
        {
          label: this.$t('offer.specification.mileage'),
          icon: require('../../assets/images/specifications/mileage.svg'),
          value: this.offer?.data?.DetailsMain?.Mileage
        },
        {
          label: this.$t('offer.specification.transmission'),
          icon: require('../../assets/images/specifications/transmission.svg'),
          value: this.offer?.data?.DetailsGearBoxFeatures?.TYPTXTTransTypeCd2
        },
        {
          label: this.$t('offer.specification.registration '),
          icon: require('../../assets/images/specifications/registration.svg'),
          value: this.offer?.data?.DetailsMain?.FirstRegistrationDate
        },
        {
          label: this.$t('offer.specification.vin'),
          icon: require('../../assets/images/specifications/vin.svg'),
          value: this.offer?.data?.DetailsBodyFeatures?.VIN
        },
        {
          label: this.$t('offer.specification.crashed'),
          icon: require('../../assets/images/specifications/crashed.svg'),
          value: 'No'
        },
        {
          label: this.$t('offer.specification.doors'),
          icon: require('../../assets/images/specifications/doors.svg'),
          value: this.offer?.data?.DetailsBodyFeatures?.TYPDoor
        },
        {
          label: this.$t('offer.specification.seats'),
          icon: require('../../assets/images/specifications/seats.svg'),
          value: this.offer?.data?.Default?.TYPSeat
        },
        {
          label: this.$t('offer.specification.upholstery'),
          icon: require('../../assets/images/specifications/upholstery.svg'),
          value: "Leather"
        },

      ]
    }
  },
}
</script>

<style scoped>

</style>