<template>
  <div class="offer offer__container">
    <div class="offer__promesa">
      <div class="offer__promesa-item" v-for="item in items" :key="item.label" :style="{flex: [`${item.size} 1`]}">
        <img
            class="offer__promesa-item--img"
            :src="item.icon"
            :alt="$t(item.label)"/>
        <div  class="offer__promesa-item--label">
          {{$t(item.label)}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OfferPromesa",
  data(){
    return {
        items:[
          {
            label: 'offer.promesa.warranty',
            icon: require('../../assets/images/PromesaWarranty.svg'),
            size: 7,
          },
          {
            label: 'offer.promesa.support',
            icon: require('../../assets/images/PromesaSupport.svg'),
            size: 10,
          },
          {
            label: 'offer.promesa.vc360',
            icon: require('../../assets/images/PromesaVC360.svg'),
            size: 13
          },
          {
            label: 'offer.promesa.finance',
            icon: require('../../assets/images/PromesaFinance.svg'),
            size: 9,
          }
        ]
    }
  }
}
</script>

<style scoped>

</style>