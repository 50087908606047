import service from "../services/offer-service";
import axios from "axios";

const state = {
    list: {},
    params:{},
    single_offer: {},
    best_offers: [],
    new_arrivals_for_you: [],
    fall_in_love_with_it: [],
    offerOfTheDay: [],
    similarOffers:[],
    last_seen: JSON.parse(localStorage.getItem('last_seen')) ?? [],
    cert: null,
}

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_OFFER: (state, offer) => {
        state.single_offer = offer;
    },

    SET_BEST_OFFERS: (state, offer) => {
        state.best_offers = offer;
    },
    SET_NEW_ARRIVALS_FOR_YOU: (state, offer) => {
        state.new_arrivals_for_you = offer;
    },
    SET_FALL_IN_LOVE_WITH_IT: (state, offer) => {
        state.fall_in_love_with_it = offer;
    },
    SET_OFFER_OF_THE_DAY: (state, offer) => {
        state.offerOfTheDay = offer;
    },
    SET_SIMILAR_OFFERS: (state, offer) => {
        state.similarOffers = offer;
    },
    SET_LAST_SEEN:(state,offer) =>{
        let seen = state.last_seen;
        let is_in_seen = seen.findIndex(element => element.announcementId == offer.announcementId)
        if(is_in_seen >= 0){
            seen.splice(is_in_seen,1)
        }
        seen.unshift(offer)
        seen = seen.slice(0,3)
        localStorage.setItem('last_seen',JSON.stringify(seen))
        state.last_seen = seen
    },
    SET_CERT:(state, cert) => {
        state.cert = cert;
    }
}

const actions = {
    setList({commit}) {
        return service.list({pageNo: 1, pageSize: 36}).then(({offers}) => {
            commit('SET_LIST', offers)
        })
    },
    setOffer({commit, dispatch}, id) {
        return service.getOffer(id).then(({offer}) => {
            commit('SET_OFFER', offer);
            commit('SET_LAST_SEEN', offer);
            dispatch('setSimilarOffers',offer?.data?.DetailsBodyFeatures?.TYPTXTBodyCo1Cd2);
        })
    },
    setCert({commit},vin){
        axios.get(`https://api-certificate.vc360.app/api/v1/661c8b5dab4a82b62b7d11dc/certificate/vin/${vin}`).then((response) =>{
            //console.log('cert load',response)
            commit('SET_CERT',response.data.widget);
        })
    },
    setBestOffers({commit}) {
        // return service.list({pageNo: 1, pageSize: 6, mark: 'KIA'}).then(({offers}) => {
            return service.list({pageNo: 1, pageSize: 6, isDiscounted: true}).then(({offers}) => {
            let fullOffers = [];

            offers.map(singleOffer => {
                service.getOffer(singleOffer.announcementId).then(({offer}) => {
                    fullOffers.push(offer);
                })
            })

            commit('SET_BEST_OFFERS', fullOffers)
        })
    },
    setNewArrivalsForYou({commit}) {
        return service.list({pageNo: 1, pageSize: 10, isCertified: true}).then(({offers}) => {
            // return service.list({pageNo: 1, pageSize: 10, orderBy: "announcementId"}).then(({offers}) => {
            let fullOffers = [];

            offers.map(singleOffer => {
                service.getOffer(singleOffer.announcementId).then(({offer}) => {
                    fullOffers.push(offer);
                })
            })

            commit('SET_NEW_ARRIVALS_FOR_YOU', fullOffers)
        })
    },
    setFallInLoveWithIt({commit}) {
        return service.list({pageNo: 1, pageSize: 10, isCertified: true}).then(({offers}) => {
        //     return service.list({pageNo: 1, pageSize: 10, orderBy: "announcementId"}).then(({offers}) => {
            let fullOffers = [];

            offers.map(singleOffer => {
                service.getOffer(singleOffer.announcementId).then(({offer}) => {
                    fullOffers.push(offer);
                })
            })

            commit('SET_FALL_IN_LOVE_WITH_IT', fullOffers)
        })
    },
    setOfferOfTheDay({commit}) {
        // return service.list({pageNo: 1, pageSize: 10, mark: "KIA"}).then(({offers}) => {
        return service.list({pageNo: 1, pageSize: 1, checkIsOfferOfTheDay: true}).then(({offers}) => {
            let fullOffers = [];
            offers.map(singleOffer => {
                service.getOffer(singleOffer.announcementId).then(({offer}) => {
                    fullOffers.push(offer);
                })
            })

            //console.log("Offer of day: " + fullOffers.length);

            commit('SET_OFFER_OF_THE_DAY', fullOffers)
        })
    },
    setSimilarOffers({commit,state},body_type) {
        return service.list({pageNo: 1, pageSize: 6, VehicleCategory:body_type}).then(({offers}) => {
            let fullOffers = [];
            offers.map(singleOffer => {
                if (state.single_offer.announcementId !== singleOffer.announcementId){
                    service.getOffer(singleOffer.announcementId).then(({offer}) => {
                        fullOffers.push(offer);
                    })
                }
            })
            commit('SET_SIMILAR_OFFERS', fullOffers)
        })
    },
}

const getters = {
    getList: state => state.list,
    getOffer: state => state.single_offer,
    getBestOffers: state => state.best_offers,
    getNewArrivalsForYou: state => state.new_arrivals_for_you,
    getFallInLoveWithIt: state => state.fall_in_love_with_it,
    getOfferOfTheDay: state => state.offerOfTheDay[0],
    getLastSeen: state => state.last_seen,
    getCert: state => state.cert,
    getSimilarOffers: state => state.similarOffers,
}

const offer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default offer;