<template>
  <div class="offer__data_content-item">
    <img src="/assets/demo/img/additionals/additional.png">
    <span class="offer__data_content-item-title">Insurance Package</span>
    <button>
      <img src="/assets/img/offer/additionals/plus.svg">
      <span>Add</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "AdditionalsBox"
}
</script>

<style scoped>

</style>