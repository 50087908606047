<template>
  <div class="description__container">
    <div class="description__wrapper">
      <div class="description__body">
        <description-text-component></description-text-component>
      </div>
      <div class="description__img">
        <img src="assets/img/home/description.svg" class="description__img-logo">
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionTextComponent from "@/components/home/DescriptionTextComponent.vue";

export default {
  name: "DescriptionComponent",
  components: {DescriptionTextComponent}
}
</script>

<style scoped>

</style>