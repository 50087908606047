<template>
  <navbar-layout />
  <router-view :key="$route.fullPath.split('#')[0].split('?')[0]" />
  <footer-layout />
</template>

<script>
import FooterLayout from "./FooterLayout";
import NavbarLayout from "./NavbarLayout";
export default {
  name: "AppLayout",
  components: {NavbarLayout, FooterLayout},
  props:['color'],
  mounted() {
    let r = document.querySelector(':root');
    r.style.setProperty('--primary-color', this.hexToRbga(this.color, 1));
    r.style.setProperty('--primary-color-light', this.hexToRbga(this.color, 0.1));
    r.style.setProperty('--vs-dropdown-option--active-bg', this.hexToRbga(this.color, 1));
  },
  methods:{
    hexToRbga(hex,opacity){
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(', ') + ', ' + opacity +')';
      }
      throw new Error('Bad Hex');
    }
  }
}
</script>

<style>
:root{
  --primary-color: #999;
}

</style>