<template>
  <div class="offer offer__container">
    <div class="offer__gallery">
      <div class="offer__gallery--360" style="position: relative">
        <iframe :src="view_360" width="auto" height="auto" allowfullscreen></iframe>
        <carousel :settings="settings" class="carousel">
          <slide v-for="slide in getSlides" :key="slide" data-fancybox="gallery" :href="slide">
            <img :src="slide" style="border-radius: 1.2rem;cursor: pointer;">
          </slide>
          <template #addons>
            <navigation/>
          </template>
        </carousel>
      </div>

      <div class="offer__gallery--widget" id="vc-widget">
      </div>
      <div class="offer__gallery--mobile">
        <iframe :src="view_360" width="auto" height="auto" allowfullscreen></iframe>
      </div>

    </div>
  </div>
</template>

<script>

import {Carousel, Navigation, Slide} from 'vue3-carousel'
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox} from "@fancyapps/ui";

export default {
  name: "OfferGallery",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: ['view_360', 'images'],
  data() {
    return {
      settings: {
        itemsToShow: 4.2,
        wrapAround: false,
        snapAlign: "start"
      },
      id_offer: null,
    }
  },
  beforeMount() {
    this.id_offer = this.$route.params.id;
  },
  mounted() {
    let widget = document.getElementById('vc-widget');
    let div = document.createElement("virtual-seller");
    div.setAttribute('portal','69');
    div.setAttribute('offer',this.id_offer);
    div.setAttribute('image','false');
    // div.setAttribute('color','#f42ee4');
    widget.append(div);
    Fancybox.bind("[data-fancybox]", {
    });
  },
  computed: {
    getSlides: function () {
      if (this.images) {
        return this.images.filter(function (image){
          return image.includes('/additional/')
        });
      } else {
        return []
      }
    },
  }
}
</script>

<style scoped>

</style>