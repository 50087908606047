<template>
  <svg class="home-it-works-box__logo--img" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7394_13550)">
      <path d="M13.3333 53.3333C16.2789 53.3333 18.6667 50.9455 18.6667 48C18.6667 45.0544 16.2789 42.6666 13.3333 42.6666C10.3878 42.6666 8 45.0544 8 48C8 50.9455 10.3878 53.3333 13.3333 53.3333Z" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M50.6654 21.3333C53.6109 21.3333 55.9987 18.9455 55.9987 16C55.9987 13.0544 53.6109 10.6666 50.6654 10.6666C47.7198 10.6666 45.332 13.0544 45.332 16C45.332 18.9455 47.7198 21.3333 50.6654 21.3333Z" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M50.6654 21.3334V34.6667C50.6654 38.2029 49.2606 41.5943 46.7601 44.0948C44.2596 46.5953 40.8683 48 37.332 48H29.332L37.332 40M37.332 56L29.332 48" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.332 42.6667V29.3333C13.332 25.7971 14.7368 22.4057 17.2373 19.9052C19.7378 17.4048 23.1291 16 26.6654 16H34.6654L26.6654 8M26.6654 24L34.6654 16" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7394_13550">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HowItWorks1Icon"
}
</script>

<style scoped>

</style>