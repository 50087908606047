<template>
    <div class="love-it-slider__content">
        <div class="love-it-slider__wrapper">
            <div class="" style="position: relative; width: 100%" v-if="show">
                <carousel :settings="settings" class="carousel" :breakpoints="breakpoints">
                    <slide v-for="slide in getSlides" :key="slide" :href="slide">
                        <router-link :to="{name:'Offer',params:{id:slide?.announcementId}}">
                        <love-it-box-component
                                :name="slide?.model"
                                :img="slide?.carImageSets[0]"
                                :power="slide?.data?.Main?.TYPHP"
                                :engine="slide?.data?.DetailsMain?.TYPCapTech"
                                :year="slide?.manufacturingYear"
                        ></love-it-box-component>
                        </router-link>
                    </slide>
                    <template #addons>
                        <navigation/>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import {Carousel, Navigation, Slide} from "vue3-carousel";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import LoveItBoxComponent from "@/components/searcher/LoveItBoxComponent.vue";
import {mapActions, mapGetters} from "vuex";
// import loveItBoxComponent from "@/components/searcher/LoveItBoxComponent.vue";

export default {
    name: "LoveItSlider",
    components: {
        LoveItBoxComponent,
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            show: false,
            settings: {
                itemsToShow: 1,
                wrapAround: false,
                snapAlign: "start"
            },
            breakpoints: {
                // 700px and up
                400: {
                    itemsToShow: 1.5
                },
                700: {
                    itemsToShow: 2,
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                },
            },
        }
    },
    mounted() {
        Fancybox.bind("[data-fancybox]", {});
        this.setSlides();
        setTimeout(() => {
            this.show = true;
        }, 1000);
    },
    methods: {
        ...mapActions({
            setSlides: "offer/setFallInLoveWithIt"
        })
    },
    computed: {
        ...mapGetters({
            getSlides: "offer/getFallInLoveWithIt"
        }),
        getSlides2: function () {
            return [
                {
                    name: 'Merceds EQS',
                    img: "/assets/demo/img/love-it/eqs.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
                {
                    name: 'Mercedes G Class',
                    img: "/assets/demo/img/love-it/g_class.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
                {
                    name: 'Mazda CX-5',
                    img: "/assets/demo/img/love-it/cx_5.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
                {
                    name: 'Mazda CX-5',
                    img: "/assets/demo/img/love-it/cx_5.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
                {
                    name: 'Merceds EQS',
                    img: "/assets/demo/img/love-it/eqs.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
                {
                    name: 'Mercedes G Class',
                    img: "/assets/demo/img/love-it/g_class.png",
                    power: "360 kW/500 PS",
                    engine: "3.0 TFSI",
                    year: "2022"
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>