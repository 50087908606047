<template>
<div class="box">
  <div class="box__title">{{ title }}</div>
  <div class="box__body">{{ body }}</div>
</div>
</template>

<script>
export default {
  name: "DescriptionTextBoxComponent",
  props: {
    title: String,
    body: String
  }
}
</script>

<style scoped>

</style>