<template>
  <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="31" rx="4" fill="var(--primary-color)"/>
    <rect x="6" y="9.06226" width="20" height="1.81546" rx="0.907729" fill="white"/>
    <rect x="6" y="14.5087" width="20" height="1.81546" rx="0.907729" fill="white"/>
    <rect x="6" y="19.955" width="20" height="1.81546" rx="0.907729" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "BurgerIcon"
}
</script>

<style scoped>

</style>