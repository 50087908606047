<template>
    <div class="footer">
      <div class="footer-container">
        <div class="footer__item footer__item--header">
          <div class="footer__item_box footer--no-mobile">
            <img :src="logo">
            <a href="mailto:VC360@exacto-group.com">VC360@exacto-group.com</a>
            <a href="callto:+48510488231">+48 510 488 231</a>
          </div>
          <div class="footer__item_box footer--mobile">
            <img :src="logo">
          </div>
          <div class="footer__item_box footer__text-center footer__text-back-to-up footer--mobile">
            <arrow-up-circle-icon></arrow-up-circle-icon>
            <a href="">Back to up</a>
          </div>
        </div>
        <div class="footer__item footer__item--footer">
          <div class="footer__item_box">
          <div class="footer__item-inline">
            <img class="footer_img_social" src="/assets/img/footer/social/facebook.svg">
            <img class="footer_img_social" src="/assets/img/footer/social/youtube.svg">
            <img class="footer_img_social" src="/assets/img/footer/social/ln.svg">
            <img class="footer_img_social" src="/assets/img/footer/social/twitter.svg">
          </div>
          <div class="footer--no-mobile"><a href="">Polityka prywatności</a></div>
          </div>
        </div>
        <div class="footer__item footer__item--contact footer--no-mobile">
          <div class="footer__item_box">
            <a href="">O nas</a>
            <a href="">Aktualności</a>
            <a href="">Case Studies</a>
            <a href="">Kontakt</a>
            <a href="">Wsparcie</a>
          </div>
        </div>
        <div class="footer__item footer__item--links footer--no-mobile">
          <div class="footer__item_box">
            <a href="">VC360 PHOTO APP</a>
            <a href="">VC360 PHOTO STUDIO 360</a>
            <a href="">VC360 OFFER MANAGER</a>
            <a href="">VC360 INSPECTION APP<br>AND DIGITAL CERTIFICATION</a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ArrowUpCircleIcon from "../components/svg/ArrowUpCircleIcon";
export default {
  name: "FooterLayout",
  components: {ArrowUpCircleIcon},
  data(){
    return{
      logo: process.env.VUE_APP_LOGO
    }
  }
}
</script>

<style scoped lang="scss">

</style>