<template>

  <div class="searcher__filter" v-bind:style="{ backgroundImage: 'url(' + banner + ')' }">
    <div class="searcher__filter-container home__searcher-container">
      <div class="searcher__filter-items searcher__filter-items--car home__searcher-box">
        <div class="searcher__filter-item-box">
          <h1 class="home__offer--title">Buy. Sell. Rent. Car.</h1>
        </div>
        <div class="searcher__filter-item-box">
          <div class="searcher__filter-item-box" style="position: relative">
            <input class="searcher__filter-search" v-model="search_query" placeholder="Make, Model or Keyword">
            <search-icon></search-icon>
            <router-link class="searcher__filter-search--button searcher__filter-search--button-home" :to="{name:'Searcher', query:{search: search_query}}">
            GO!
            </router-link>
          </div>
        </div>
        <div class="searcher__filter-item-box home__searcher">
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'Kombi'}}">
            <img class="image" :src="combi" alt="combi">
            <p class="text">Combi</p>
          </router-link>
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'SUV'}}">
            <img class="image" :src="suv" alt="combi">
            <p class="text">SUV</p>
          </router-link>
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'Sport'}}">
            <img class="image" :src="sport" alt="combi">
            <p class="text">Sport</p>
          </router-link>
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'Sedan'}}">
            <img class="image" :src="sedan" alt="combi">
            <p class="text">Sedan</p>
          </router-link>
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'Hatchback'}}">
            <img class="image" :src="hatchback" alt="combi">
            <p class="text">Hatchback</p>
          </router-link>
          <router-link class="home__searcher-type" :to="{name:'Searcher', query:{body_type: 'City'}}">
            <img class="image" :src="city" alt="combi">
            <p class="text">City</p>
          </router-link>
        </div>
        <div class="searcher__filter-item-box">
          <button class="home__searcher-action">Sell my car</button>
        </div>
      </div>
      <div class="searcher__filter-items">
        <HomeOfferOfTheDay></HomeOfferOfTheDay>
      </div>
    </div>
  </div>
</template>

<script>
import HomeOfferOfTheDay from "./HomeOfferOfTheDay";
import SearchIcon from "../svg/SearchIcon";

export default {
  name: "HomeSearcher",
  components: {SearchIcon, HomeOfferOfTheDay},
  data() {
    return {
      banner: require('../../assets/images/home/Banner.png'),
      search: require('../../assets/images/searcher/Search.svg'),
      showMore: require('../../assets/images/searcher/ShowMore.svg'),
      sedan: require('../../assets/images/home/Sedan.svg'),
      city: require('../../assets/images/home/City.svg'),
      combi: require('../../assets/images/home/Combi.svg'),
      hatchback: require('../../assets/images/home/Hackback.svg'),
      suv: require('../../assets/images/home/SUV.svg'),
      sport: require('../../assets/images/home/Sport.svg'),
      search_query: "",
    }
  }
}
</script>

<style scoped>

</style>