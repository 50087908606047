<template>
  <div class="offer offer__container">
    <div class="offer__tabs">
      <div class="offer__tabs-items">
        <a href="#top" class="offer__tabs-item active">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.top')}}</span>
        </a>
        <a href="#specification" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.specification')}}</span>
        </a>
        <a href="#features" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.features_equipment')}}</span>
        </a>
        <a href="#description" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.description')}}</span>
        </a>
        <a href="#certificate" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.certificate')}}</span>
        </a>
        <a href="#additions" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.additionals')}}</span>
        </a>
        <a href="#similar" class="offer__tabs-item">
          <span class="offer__tabs-item--label">{{$t('offer.tabs.similar_cars')}}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferTabs"
}
</script>

<style scoped>

</style>