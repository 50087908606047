<template>
  <svg class="searcher-bar__item--close" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.4461 1.66179C10.752 1.35586 10.752 0.859855 10.4461 0.553928C10.1401 0.248002 9.64414 0.248002 9.33821 0.553928L6.32496 3.56718C5.86935 4.0228 5.13065 4.0228 4.67504 3.56718L1.66179 0.553929C1.35586 0.248002 0.859855 0.248002 0.553928 0.553928C0.248002 0.859855 0.248002 1.35586 0.553928 1.66179L3.56718 4.67504C4.0228 5.13065 4.0228 5.86935 3.56718 6.32496L0.553929 9.33821C0.248002 9.64414 0.248002 10.1401 0.553928 10.4461C0.859855 10.752 1.35586 10.752 1.66179 10.4461L4.67504 7.43282C5.13065 6.9772 5.86935 6.9772 6.32496 7.43281L9.33821 10.4461C9.64414 10.752 10.1401 10.752 10.4461 10.4461C10.752 10.1401 10.752 9.64414 10.4461 9.33821L7.43282 6.32496C6.9772 5.86935 6.9772 5.13065 7.43281 4.67504L10.4461 1.66179Z"
        fill="var(--primary-color)"/>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon"
}
</script>

<style scoped>

</style>