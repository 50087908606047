<template>
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0623 15C27.0623 8.615 21.886 3.4375 15.4998 3.4375C9.11476 3.4375 3.93726 8.615 3.93726 15C3.93726 21.385 9.11476 26.5625 15.4998 26.5625C21.886 26.5625 27.0623 21.385 27.0623 15Z" stroke="var(--primary-color)" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.8386 16.803L15.4999 12.4455L11.1611 16.803" stroke="var(--primary-color)" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "ArrowUpCircleIcon"
}
</script>

<style scoped>

</style>