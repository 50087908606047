<template>
    <div class="love-it">
        <items-section-container :title="$t('search.love_it.title')" is-dark btn-more btn-center>
            <div class="love-it-slider__content home__best-content">
                <div class="header">
          <span class="header__title header__title--dark">
            Best offer
          </span>
                </div>
                <div class="love-it-slider__wrapper home__best" v-if="show">
                    <div class="home__best-item" v-for="slide in getSlides" :key="slide">
                        <router-link :to="{name:'Offer',params:{id:slide?.announcementId}}">
                          <searcher-car :car="slide"></searcher-car>
                        </router-link>
                    </div>
                </div>
            </div>
        </items-section-container>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SearcherCar from "@/components/searcher/SearcherCar.vue";

export default {
    name: "HomeBestOffers",
  components: {SearcherCar},
    props: {
        name: String,
        img: String,
        power: String,
        engine: String,
        year: String,
    },
    data() {
        return {
            show: false,
            offer_of_the_day: require('../../assets/images/home/Offer.png')
        }
    },
    mounted() {
        this.setSlides();
        setTimeout(() => {
            this.show = true;
        }, 750);
    },
    methods: {
        ...mapActions({
            setSlides: "offer/setBestOffers"
        })
    },
    computed: {
        ...mapGetters({
            getSlides: "offer/getBestOffers"
        }),
    }
}
</script>

<style scoped>

</style>