<template>
  <svg class="home-it-works-box__logo--img" width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7394_13595)">
      <path d="M24.5 29.3333L32.5 37.3333L53.8333 16" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M53.8307 32.0001V48.0001C53.8307 49.4146 53.2688 50.7711 52.2686 51.7713C51.2684 52.7715 49.9119 53.3334 48.4974 53.3334H16.4974C15.0829 53.3334 13.7264 52.7715 12.7262 51.7713C11.726 50.7711 11.1641 49.4146 11.1641 48.0001V16.0001C11.1641 14.5856 11.726 13.229 12.7262 12.2288C13.7264 11.2287 15.0829 10.6667 16.4974 10.6667H40.4974" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7394_13595">
        <rect width="64" height="64" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HowItWorks5Icon"
}
</script>

<style scoped>

</style>