<template>
  <svg class="home-it-works-box__logo--img" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7394_13561)">
      <path d="M29.3333 48.0001L24 45.3334L8 53.3334V18.6667L24 10.6667L40 18.6667L56 10.6667V37.3334" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 10.6667V45.3334" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40 18.6667V32.0001" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M44.0026 53.3333C47.6845 53.3333 50.6693 50.3486 50.6693 46.6667C50.6693 42.9848 47.6845 40 44.0026 40C40.3207 40 37.3359 42.9848 37.3359 46.6667C37.3359 50.3486 40.3207 53.3333 44.0026 53.3333Z" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M49.3359 52L56.0026 58.6667" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7394_13561">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HowItWorks2Icon"
}
</script>

<style scoped>

</style>