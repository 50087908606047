<template>
  <div class="how-it-works-values">
    <how-it-works-value-component
        number="14 000"
        text="Cars to choose from"
    ></how-it-works-value-component>
    <how-it-works-value-component
        number="9 000"
        text="Available cars from stock"
    ></how-it-works-value-component>
    <how-it-works-value-component
        number="10 000"
        text="Completed transactions"
    ></how-it-works-value-component>
    <how-it-works-value-component
        number="11 050"
        text="Satisfied users"
    ></how-it-works-value-component>
  </div>
</template>

<script>
import HowItWorksValueComponent from "@/components/home/HowItWorksValueComponent.vue";

export default {
  name: "HowItWorksValuesComponent",
  components: {HowItWorksValueComponent}
}
</script>

<style scoped>

</style>