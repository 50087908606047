<template>
  <div class="searcher__slider">
      <p class="title">{{context}}</p>
      <vue-slider v-model="value" :enable-cross="false" :min="min_value" :max="max_value" :tooltip="'always'" :tooltip-formatter="format"  :interval="interval" @update:modelValue="setValue()" @drag-end="setValue()"/>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SearcherFilterSlider",
  components:{
    VueSlider
  },
  data () {
    return {
      value: [parseInt(this.min),parseInt(this.max)],
    }
  },
  mounted() {
    this.value=[parseInt(this.min),parseInt(this.max)]
    setTimeout(()=>{
      this.value=[parseInt(this.params[`min_${this.type}`] ?? this.min),parseInt(this.params[`max_${this.type}`] ?? this.max)]
    },250)
  },
  props:{
    min:{
      default:0,
    },
    max:{
      default:3000,
    },
    range:{},
    context:{
      default: "",
      type: String,
    },
    interval:{
      default: 100,
      type: Number,
    },
    type:{
      default: '',
      type: String,
    },
    units:{
      default:'',
      type:String,
    }
  },
  watch: {
    // whenever question changes, this function will run
    params() {
      setTimeout(()=>{
        this.value=[parseInt(this.params[`min_${this.type}`] ?? this.min),parseInt(this.params[`max_${this.type}`] ?? this.max)]
      },250)
    },
  },

  methods:{
    ...mapActions({
      setParams:'searcher/setParams',
    }),
    setValue(){
      this.params[this.type] = this.value;
      this.params[`${this.type}_range`] = [this.min_value,this.max_value]
      this.setParams(this.params)
    }
  },
  computed: {
    ...mapGetters({
      params: 'searcher/getParams'
    }),
    format(){
      return '{value}' + this.units
    },
    value_from_width() {
      return this.value[0].toString().length +'ch';
    },
    value_to_width() {
      return this.value[1].toString().length +'ch';
    },
    min_value(){
      return parseInt(this.min);
    },
    max_value(){
      return parseInt(this.max);
    }

  },

}
</script>

<style scoped>

</style>