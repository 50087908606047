<template>
  <div class="how-it-works-boxes">
    <how-it-works-box-component
        url="/assets/img/home/how_it_works/1.svg"
        number="1"
        text="Exchange your car"
    >
      <template #icon>
        <how-it-works1-icon></how-it-works1-icon>
      </template>
    </how-it-works-box-component>
    <how-it-works-box-component
        url="/assets/img/home/how_it_works/2.svg"
        number="2"
        text="Choose delivery"
    >
      <template #icon>
        <how-it-works2-icon></how-it-works2-icon>
      </template>
    </how-it-works-box-component>
    <how-it-works-box-component
        url="/assets/img/home/how_it_works/3.svg"
        number="3"
        text="Additional Services"
    >
      <template #icon>
        <how-it-works3-icon></how-it-works3-icon>
      </template>
    </how-it-works-box-component>
    <how-it-works-box-component
        url="/assets/img/home/how_it_works/4.svg"
        number="4"
        text="Payment Calculation"
    >
      <template #icon>
        <how-it-works4-icon></how-it-works4-icon>
      </template>
    </how-it-works-box-component>
    <how-it-works-box-component
        url="/assets/img/home/how_it_works/5.svg"
        number="5"
        text="Make reservation"
    >
      <template #icon>
        <how-it-works5-icon></how-it-works5-icon>
      </template>
    </how-it-works-box-component>
  </div>
</template>

<script>
import HowItWorksBoxComponent from "@/components/home/HowItWorksBoxComponent.vue";
import HowItWorks1Icon from "../svg/how_it_works/HowItWorks1Icon";
import HowItWorks2Icon from "../svg/how_it_works/HowItWorks2Icon";
import HowItWorks3Icon from "../svg/how_it_works/HowItWorks3Icon";
import HowItWorks4Icon from "../svg/how_it_works/HowItWorks4Icon";
import HowItWorks5Icon from "../svg/how_it_works/HowItWorks5Icon";

export default {
  name: "HowItWorksBoxesComponent",
  components: {
    HowItWorks5Icon,
    HowItWorks4Icon, HowItWorks3Icon, HowItWorks2Icon, HowItWorks1Icon, HowItWorksBoxComponent}
}
</script>

<style scoped>

</style>