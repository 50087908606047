import { createApp } from 'vue'
import router from './router/router';
import App from "./App";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import i18n from './locale'

import "./assets/styles/app.scss"
import './assets/styles/carousel.css'

// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'


const app = createApp(App)

app.use(VueAxios,axios);
// app.use(ElementPlus);
app.use(store);
app.use(router);
app.use(i18n)
app.mount('#app')