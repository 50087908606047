<template>
  <div class="offer offer__container home__promesa-container">
    <div class="offer__promesa home__promesa">
      <div class="offer__promesa-item" v-for="item in items" :key="item.label" :style="{flex: [`${item.size} 1`]}">
        <div class="home__promesa-item">
          <img
              class="offer__promesa-item--img home__promesa--img"
              :src="item.icon"
              :alt="$t(item.label)"/>
          <div  class="offer__promesa-item--label home__promesa--label">
            {{$t(item.label)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePromessa",
  data(){
    return {
      items:[
        {
          label: 'offer.promesa.warranty',
          icon: require('../../assets/images/home/PromesaWarranty.svg'),
          size: 7,
        },
        {
          label: 'offer.promesa.support',
          icon: require('../../assets/images/home/PromesaSupport.svg'),
          size: 10,
        },
        {
          label: 'offer.promesa.vc360',
          icon: require('../../assets/images/home/PromesaVC360.svg'),
          size: 13
        },
        {
          label: 'offer.promesa.finance',
          icon: require('../../assets/images/home/PromesaFinance.svg'),
          size: 9,
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>