<template>
  <offer-extra-container :title="$t('offer.tabs.additionals')">
    <template #content>
      <div class="offer__data_content_grid">
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
        <additionals-box></additionals-box>
      </div>
      <div class="offer__data_content_grid--mobile">
        <carousel :settings="settings" :breakpoints="breakpoints" class="carousel-additional">
          <slide v-for="slide in 6" :key="slide" style="overflow: visible;">
            <additionals-box></additionals-box>
          </slide>
        </carousel>
      </div>
    </template>
  </offer-extra-container>
</template>

<script>
import OfferExtraContainer from "@/components/offer/OfferExtraContainer.vue";
import AdditionalsBox from "@/components/offer/AdditionalsBox.vue";
import {Carousel, Slide} from 'vue3-carousel'

export default {
  name: "AdditionalsContainer",
  components: {AdditionalsBox, OfferExtraContainer, Carousel, Slide},
  data() {
    return {
      settings: {
        itemsToShow: 3.5,
        wrapAround: false,
        snapAlign: "start",
      },
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1.1,
          snapAlign: 'start',
        },
        425: {
          itemsToShow: 1.5,
          snapAlign: 'start',
        },
        // 1024 and up
        768: {
          itemsToShow: 2.5,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 3.5,
          snapAlign: 'start',
        },
      }
    }
  }
}
</script>

<style scoped>

</style>