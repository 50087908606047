<template>
  <div class="searcher-bar">
    <div class="searcher__filter-container">
      <div class="searcher-bar__box">
        <div class="searcher-bar__items">
          <div class="searcher-bar__item" v-for="(value,name) in params" :key="name">{{$t(`search.label.${value}`) !== `search.label.${value}` ? $t(`search.label.${value}`) : value }} <close-icon @click="remove(name)"/></div>
          <div class="searcher-bar__item--clear" v-if="Object.keys(params).length !== 0" @click="reset">Clear all filters</div>
        </div>
        <div class="searcher-bar__item--sort">
          <v-select :options="sort_type" v-model="params_data.sort_by" :reduce="(option) => option.value" label="label" :searchable="false" placeholder="Sort by newest" @update:modelValue="onChange()"></v-select>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import CloseIcon from "../svg/CloseIcon";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "SearcherBar",
  components:{CloseIcon, vSelect},
  data(){
    return{
      close: require('../../assets/images/searcher/Close.svg'),
      sort_type:[
        {
          label: 'Sort by price ↑',
          value:'price|asc'
        },
        {
          label: 'Sort by price ↓',
          value:'price|desc'
        },
        {
          label: 'Sort by mileage ↑',
          value:'mileage|asc'
        },
        {
          label: 'Sort by mileage ↓',
          value:'mileage|desc'
        },
        {
          label: 'Sort by year ↑',
          value:'ManufacturingYear|asc'
        },
        {
          label: 'Sort by year ↓',
          value:'ManufacturingYear|desc'
        },
      ]
    }
  },
  computed:{
    ...mapGetters({
      params : "searcher/getParamsBadge",
      params_data : "searcher/getParams",
    })
  },
  methods:{
    ...mapActions({
      reset:"searcher/resetParams",
      remove: "searcher/removeParam",
      setParams:'searcher/setParams',
    }),
    onChange() {
      this.setParams(this.params_data)
    },
  }
}
</script>

<style scoped>

</style>