<template>
  <div class="last-seen" v-show="lastSeen.length > 0">
    <items-section-container :title="$t('search.last_seen.title')">
      <template #content>
        <div class="box" v-for="car in lastSeen" :key="car.announcementId">
          <router-link :to="{name:'Offer',params:{id:car.announcementId}}">
            <last-seen-box-component :car="car" style="text-decoration: none"></last-seen-box-component>
          </router-link>
        </div>
      </template>
    </items-section-container>
  </div>
</template>

<script>
import ItemsSectionContainer from "@/components/global/ItemsSectionContainer.vue";
import LastSeenBoxComponent from "@/components/searcher/LastSeenBoxComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "LastSeen",
  components: {ItemsSectionContainer, LastSeenBoxComponent},
  computed: {
    ...mapGetters({
      lastSeen: "offer/getLastSeen"
    })
  }
}
</script>
