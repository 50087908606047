<template>
    <div class="offer__specification_item-box">
      <img class="offer__specification_item--image" :src="icon" :alt="label" >
    </div>
    <p class="offer__specification_item--label">{{label}}</p>
    <p class="offer__specification_item--value">{{value}}</p>
</template>

<script>
export default {
  name: "OfferSpecificationItem",
  props: ['icon','label','value']
}
</script>

<style scoped>

</style>