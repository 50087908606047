<template>
  <div class="navbar">
    <div class="navbar-container">
      <div class="navbar__item_logo">
        <router-link :to="{name:'Home'}">
        <img
          :src="logo"
          alt="logo" />
        </router-link>
      </div>
      <div class="navbar__shrink" />
      <div class="navbar__item">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.search') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.sale') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.how_it_works') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.about_us') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item">
        <div class="navbar__item--notification">
          <img
              :src="notification"
              alt="notification" />
        </div>
        <div class="navbar__item--like">
          <img
              :src="like"
              alt="like" />
        </div>
        <div class="navbar__item--user">
          <img
              :src="profile"
              alt="profile" />
          {{ $t('header.menu.hello', {name: 'Mike'}) }}
        </div>
      </div>
      <div class="navbar__burger">
        <burger-icon @click="openNav"></burger-icon>
      </div>
    </div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
      <div class="navbar__item-mobile" @click="closeNav">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.search') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item-mobile" @click="closeNav">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.sale') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item-mobile" @click="closeNav">
        <router-link :to="{name:'Searcher'}">
          <button class="navbar__item--link">
            {{ $t('header.menu.how_it_works') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item-mobile" @click="closeNav">
        <router-link :to="{name:'Searcher', query:{min_price: 1,max_price: 100000}}">
          <button class="navbar__item--link">
            {{ $t('header.menu.about_us') }}
          </button>
        </router-link>
      </div>
      <div class="navbar__item-mobile" style="display: none">
        <div class="navbar__item--user">
          <img
              :src="profile"
              alt="profile" />
          {{ $t('header.menu.hello', {name: 'Mike'}) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BurgerIcon from "../components/svg/BurgerIcon";
export default {
  name: "NavbarLayout",
  components: {BurgerIcon},
  data() {
    return {
      // logo: require('../assets/images/Logo.svg'),
      logo: process.env.VUE_APP_LOGO,
      like: require('../assets/images/Like.svg'),
      profile: require('../assets/images/Profile.svg'),
      notification: require('../assets/images/Notification.svg'),
      burger: require('../assets/images/Burger.svg')
    };
  },
  methods:{
    openNav(){
      document.getElementById("mySidenav").style.width = "100%";
    },
    closeNav(){
      document.getElementById("mySidenav").style.width = "0";
    }
  }
}
</script>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>