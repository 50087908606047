<template>
<div class="love-it__content">
  <div class="love-it__content__wrapper">
    <div class="title">{{ name }}</div>
    <div class="photo">
      <img :src="img">
    </div>
    <div class="info">
      <div class="info__box">
        <div class="title">Power</div>
        <div>{{ power }} PS</div>
      </div>
      <div class="info__box">
        <div class="title">Engine</div>
        <div>{{ engine }} cm<sup>3</sup></div>
      </div>
      <div class="info__box">
        <div class="title">Year</div>
        <div>{{ year }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "LoveItBoxComponent",
  props: {
    name: String,
    img: String,
    power: String,
    engine: String,
    year: String,
  }
}
</script>

<style scoped>

</style>