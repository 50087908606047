<template>
  <div class="offer offer__container">
    <div class="offer__main_data">
      <div class="offer__main_data_items">
        <div class="offer__main_data_item">
          <div>
            <div class="offer__main_data_item--car">{{data?.car}}</div>
            <div class="offer__main_data_item--option">{{data?.type}}</div>
          </div>
          <div class="offer__main_data_item--like">
            <img src="/img/Like.bb7b1f89.svg" alt="like">
          </div>
        </div>
        <div class="offer__main_data_item offer__main_data_item--params">
          <div class="offer__main_data_item--param">{{data?.year}}</div>
          <div class="offer__main_data_item--param-separator">⬤</div>
          <div class="offer__main_data_item--param">{{data?.millage}} km</div>
          <div class="offer__main_data_item--param-separator">⬤</div>
          <div class="offer__main_data_item--param">{{data?.fuel}}</div>
          <div class="offer__main_data_item--param-separator">⬤</div>
          <div class="offer__main_data_item--param">{{data?.gearbox}}</div>
        </div>
        <div class="offer__main_data_item offer__main_data_item--price-box">
          <div class="offer__main_data_item--rate">1500 zł/mo</div>
          <div>
            <div class="offer__main_data_item--price">{{data?.price}} zł</div>
            <div class="offer__main_data_item--price-net">{{parseInt(data?.price / 1.23)}} zł net</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OfferMainData",
  props: ['data']
}
</script>

<style scoped>

</style>