<template>
  <div class="offer__features_items">
    <div class="offer__features_item" v-for="equipment in offer?.equipmentData" :key="equipment">
      <img class="offer__features_item--image" :src="icon" :alt="equipment">
      <span class="offer__features_item--value">{{equipment}}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OfferFeaturesEquipment",

  data(){
    return{
      icon: require('../../assets/images/FeatureEquipmentItem.svg')
    }
  },
  computed: {
    ...mapGetters({
      offer: 'offer/getOffer'
    }),
  },
}
</script>

<style scoped>

</style>