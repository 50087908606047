<template>
  <svg class="home-it-works-box__logo--img" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7394_13573)">
      <path d="M10.6641 21.3334V16.0001C10.6641 14.5856 11.226 13.229 12.2262 12.2288C13.2264 11.2287 14.5829 10.6667 15.9974 10.6667H21.3307" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6641 42.6667V48.0001C10.6641 49.4146 11.226 50.7711 12.2262 51.7713C13.2264 52.7715 14.5829 53.3334 15.9974 53.3334H21.3307" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42.6641 10.6667H47.9974C49.4119 10.6667 50.7684 11.2287 51.7686 12.2288C52.7688 13.229 53.3307 14.5856 53.3307 16.0001V21.3334" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M42.6641 53.3334H47.9974C49.4119 53.3334 50.7684 52.7715 51.7686 51.7713C52.7688 50.7711 53.3307 49.4146 53.3307 48.0001V42.6667" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 32H40" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M32 24V40" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7394_13573">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HowItWorks3Icon"
}
</script>

<style scoped>

</style>