<template>
  <div id="top">
    <breadcrumbs :links="links"></breadcrumbs>
    <offer-gallery :view_360="offer.player360Url" :images="offer.carImageSets"></offer-gallery>
    <offer-main-data :data="main_data"></offer-main-data>
    <offer-promesa class="offer__promesa--descktop"></offer-promesa>
  </div>
  <div>
    <div class="offer__tabs--sticky">
      <offer-tabs></offer-tabs>
    </div>
    <div class="offer__container offer__container--sticky" style="top: 9rem">
      <div id="vc-widget-bottom" class="vc-widget" style="position:absolute; top: 1rem; right: 1.6rem; width: 375px"></div>
    </div>
    <div id="specification" style="background-color: #F7F7F7">
      <offer-data-container :icon="specification.icon" :title="specification.label">
        <template #content>
          <offer-specification/>
        </template>
      </offer-data-container>
    </div>
    <offer-promesa class="offer__promesa--mobile"></offer-promesa>
    <offer-data-container id="features" :icon="features_equipment.icon" :title="features_equipment.label">
      <template #content>
        <offer-features-equipment/>
      </template>
    </offer-data-container>

    <offer-data-container  id="description" :icon="description.icon" :title="description.label">
      <template #content>
        <offer-description></offer-description>
      </template>
    </offer-data-container>
    <offer-data-container  id="certificate"  :icon="certificate.icon" :title="certificate.label">
      <template #content>
        <offer-certificate></offer-certificate>
      </template>
    </offer-data-container>
    <additionals-container id="additions"></additionals-container>
    <similar-cars-container id="similar"></similar-cars-container>

    <div id="vc-widget-bottom-mobile" class="vc-widget-mobile" style="position:fixed; bottom: 0; left: 50%;
transform: translateX(-50%); width: 375px;"></div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Breadcrumbs from "../components/Breadcrumbs";
import OfferGallery from "../components/offer/OfferGallery";
import OfferPromesa from "../components/offer/OfferPromesa";
import OfferDataContainer from "../components/offer/OfferDataContainer";
import OfferSpecification from "../components/offer/OfferSpecification";
import OfferDescription from "../components/offer/OfferDescription";
import OfferFeaturesEquipment from "../components/offer/OfferFeaturesEquipment";
import OfferCertificate from "../components/offer/OfferCertificate";
import SimilarCarsContainer from "../components/offer/SimilarCarsContainer.vue";
import AdditionalsContainer from "../components/offer/AdditionalsContainer.vue";
import OfferMainData from "../components/offer/OfferMainData";
import OfferTabs from "../components/offer/OfferTabs";

export default {
  name: "OfferPage",
  components: {
    OfferTabs,
    OfferMainData,
    AdditionalsContainer,
    OfferCertificate,
    OfferFeaturesEquipment,
    OfferDescription,
    OfferSpecification,
    OfferDataContainer,
    OfferPromesa,
    OfferGallery,
    Breadcrumbs,
    SimilarCarsContainer
  },
  beforeMount() {
    this.setOffer(this.$route.params.id)
  },
  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.set_widget_view();
    window.addEventListener("scroll",function() {
      const  sections = document.querySelectorAll("div[id]");
      const menu_sections = ['top','specification','features','description','certificate','additions','similar'];
      let scrollY = window.pageYOffset;

      sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        let sectionId = current.getAttribute("id");

        if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
        ) {
          if (menu_sections.includes(sectionId)) {
            document.querySelector(".offer__tabs-items a[href*=" + sectionId + "]").classList.add("active");
          }

        } else {
          if (menu_sections.includes(sectionId)) {
            document.querySelector(".offer__tabs-items a[href*=" + sectionId + "]").classList.remove("active");
          }
        }
      });
    })
  },
  data() {
    return {
      specification: {
        label: this.$t('offer.tabs.specification'),
        icon: require('../assets/images/Specification.svg')
      },
      features_equipment: {
        label: this.$t('offer.tabs.features_equipment'),
        icon: require('../assets/images/FeaturesEquipment.svg')
      },
      description: {
        label: this.$t('offer.tabs.description'),
        icon: require('../assets/images/Description.svg')
      },
      certificate: {
        label: this.$t('offer.tabs.certificate'),
        icon: require('../assets/images/Certificate.svg')
      },
      widget_show:true
    }
  },
  methods: {
    ...mapActions({
      setOffer: 'offer/setOffer'
    }),
    set_widget_view(){
      let widget = document.getElementById('vc-widget-bottom');
      let div = document.createElement("virtual-seller");
      div.style.width = "375px";
      div.setAttribute('portal','69');
      div.setAttribute('offer',this.$route.params.id);
      div.setAttribute('image',true);
      div.setAttribute('max_height',window.innerHeight - 90);

      widget.append(div);

      let widget_mobile = document.getElementById('vc-widget-bottom-mobile');
      let div_mobile = document.createElement("virtual-seller");
      div_mobile.setAttribute('portal','69');
      div_mobile.setAttribute('offer',this.$route.params.id);
      div_mobile.setAttribute('image',true);
      div_mobile.setAttribute('size','mobile');
      div_mobile.setAttribute('max_height',window.innerHeight - 75);
      // div_laptop.setAttribute('refresh',false);
      widget_mobile.append(div_mobile);
    }
  },
  computed: {
    ...mapGetters({
      offer: "offer/getOffer"
    }),
    links() {
      return [
        {label: 'Used cars',route:{name:'Searcher'}}, {label: this.offer?.data?.DetailsBodyFeatures?.TYPTXTBodyCo1Cd2, route: {name:'Searcher',query:{body_type:this.offer?.data?.DetailsBodyFeatures?.TYPTXTBodyCo1Cd2}}}, {label: this.offer.mark, route: {name:'Searcher',query:{search:this.offer.mark}}}, {label: this.offer.model, route: {name:'Searcher',query:{search:this.offer.model}}}
      ]
    },
    main_data() {
      return {
        car: `${this.offer?.mark} ${this.offer?.model}`,
        type: this.offer?.type,
        year: this.offer?.manufacturingYear,
        millage: this.offer?.mileage,
        fuel: this.offer?.fuel,
        gearbox: "Automatic",
        price: this.offer?.price
      }
    },
    active_tab(){
      return document.querySelectorAll("section[id]");
    }
  }
}
</script>

<style scoped>

</style>