<template>
  <offer-extra-container :title="$t('offer.tabs.similar_cars')" v-show="show">
    <template #content>
      <router-link  v-for="slide in getSlides" :key="slide" :to="{name:'Offer',params:{id:slide?.announcementId}}" class="similar-car">
        <searcher-car :car="slide" style="height: auto; height: -webkit-fill-available;"></searcher-car >
      </router-link>
    </template>
  </offer-extra-container>
</template>

<script>
import OfferExtraContainer from "@/components/offer/OfferExtraContainer.vue";
// import SimilarCarsBoxComponent from "@/components/offer/SimilarCarsBoxComponent.vue";
import {mapGetters} from "vuex";
import SearcherCar from "../searcher/SearcherCar";

export default {
  name: "SimilarCarsContainer",
  components: {SearcherCar, OfferExtraContainer},
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 750);
  },

  computed: {
    ...mapGetters({
      getSlides: "offer/getSimilarOffers"
    }),
  }
}
</script>

<style scoped>

</style>