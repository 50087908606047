<template>
  <HomeSearcher></HomeSearcher>
  <HomePromessa></HomePromessa>
  <div id="widget"></div>
  <HomeOfferOfTheDay class="home__offer--mobile"></HomeOfferOfTheDay>
  <HomeBestOffers></HomeBestOffers>
  <HomeNewArrivals></HomeNewArrivals>
  <how-it-works-component></how-it-works-component>
  <desctription-component></desctription-component>
  <find-your-dealer-component></find-your-dealer-component>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HomeSearcher from "../components/home/HomeSearcher";
import HomeOfferOfTheDay from "../components/home/HomeOfferOfTheDay";
import HomePromessa from "../components/home/HomePromessa";
import HomeBestOffers from "../components/home/HomeBestOffers";
import HomeNewArrivals from "../components/home/HomeNewArriwals";
import FindYourDealerComponent from "@/components/home/FindYourDealerComponent.vue";
import DesctriptionComponent from "@/components/home/DesctriptionComponent.vue";
import HowItWorksComponent from "@/components/home/HowItWorksComponent.vue";

export default {
  name: "HomePage",
  components: {HomeNewArrivals, HomeBestOffers, HomePromessa, HomeOfferOfTheDay, HomeSearcher,HowItWorksComponent, DesctriptionComponent, FindYourDealerComponent},

  mounted() {
    // this.setList();
  },
  methods:{
    ...mapActions[{
      setList : 'offer/setList'
    }]
  },
  computed:{
    ...mapGetters[{
      list: 'offer/getList'
    }]
  }
}
</script>

<style scoped>

</style>