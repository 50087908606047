<template>
  <svg class="home-it-works-box__logo--img" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7394_13586)">
      <path d="M45.3307 21.3334V13.3334C45.3307 12.6262 45.0498 11.9479 44.5497 11.4478C44.0496 10.9477 43.3713 10.6667 42.6641 10.6667H15.9974C14.5829 10.6667 13.2264 11.2287 12.2262 12.2288C11.226 13.229 10.6641 14.5856 10.6641 16.0001C10.6641 17.4146 11.226 18.7711 12.2262 19.7713C13.2264 20.7715 14.5829 21.3334 15.9974 21.3334H47.9974C48.7046 21.3334 49.3829 21.6144 49.883 22.1145C50.3831 22.6146 50.6641 23.2928 50.6641 24.0001V32.0001M50.6641 42.6667V50.6667C50.6641 51.374 50.3831 52.0523 49.883 52.5524C49.3829 53.0525 48.7046 53.3334 47.9974 53.3334H15.9974C14.5829 53.3334 13.2264 52.7715 12.2262 51.7713C11.226 50.7711 10.6641 49.4146 10.6641 48.0001V16.0001" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M53.3359 32V42.6667H42.6693C41.2548 42.6667 39.8982 42.1048 38.898 41.1046C37.8978 40.1044 37.3359 38.7478 37.3359 37.3333C37.3359 35.9188 37.8978 34.5623 38.898 33.5621C39.8982 32.5619 41.2548 32 42.6693 32H53.3359Z" stroke="var(--primary-color)" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7394_13586">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HowItWorks4Icon"
}
</script>

<style scoped>

</style>