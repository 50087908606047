import axios from "axios";


function list(params) {

    const options = {
        params: params,
    };

    return axios.get(`${process.env.VUE_APP_VC360_API_URL}${process.env.VUE_APP_VC360_API_PORTRAL_ID}`, options).then(response =>{
        return {
            offers:response.data.announcements,
            pages:response.data.allPageQuantity,
            page: response.data.currentPageNumber,
            count: response.data.allAnnouncementsCount
        };
    }).catch( error =>{
        console.log(error.code)
        return {
            offers: {},
            pages:0,
            page: 0,
            count: 0
        };
    })
}

function getOffer(id) {
    return axios.get(`${process.env.VUE_APP_VC360_API_URL}${process.env.VUE_APP_VC360_API_PORTRAL_ID}/${id}/AnnouncementsWithAllImages`).then(response =>{
        let offer = response.data;
        let properties_data = {};

        offer.basicData.forEach((item) => {
            let group_label = item.group ? item.group : 'Default'
            if (!properties_data[group_label]){
                properties_data[group_label] = {};
            }
            let group = properties_data[group_label];
            group[item.key] = item.value;
        })
        offer.data = properties_data;
        return {offer:offer};
    })
}

export default {
    list,
    getOffer,
}

