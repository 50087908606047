<template>
  <div class="love-it">
    <items-section-container :title="$t('search.love_it.title')" is-dark btn-more btn-center>
      <template #content>
        <love-it-slider></love-it-slider>
      </template>
    </items-section-container>
  </div>
</template>

<script>
import ItemsSectionContainer from "@/components/global/ItemsSectionContainer.vue";
import LoveItSlider from "@/components/searcher/LoveItSlider.vue";

export default {
  name: "LoveIt",
  components: {LoveItSlider, ItemsSectionContainer}
}
</script>

<style scoped>

</style>