<template>
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 12L1 6.5L6.5 1" stroke="var(--primary-color)" stroke-width="1.17857" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "BreadcrumbBackIcon"
}
</script>

<style scoped>

</style>