<template>
    <div class="breadcrumbs breadcrumbs__container">
      <router-link :to="{name:'Searcher',query : queryParams}">
        <div class="breadcrumbs__back">
          <breadcrumb-back-icon style="align-self: center"></breadcrumb-back-icon>
          {{ $t('breadcrumbs.back') }}
        </div>
      </router-link>
      <div class="breadcrumbs__links">
        <div class="breadcrumbs__link" v-for="(link,index) in links" :key="link.label">
          <router-link v-if="index  == links.length -1" class="breadcrumbs__link--last"
             :to="link.route">
            {{ link.label }}
          </router-link>
          <router-link v-else class="breadcrumbs__link" :to="link.route">
              {{ link.label }}
            <img
                :src="separator"
                alt="separator"/>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>
import BreadcrumbBackIcon from "./svg/BreadcrumbBackIcon";
import {mapGetters} from "vuex";
export default {
  name: "Breadcrumbs",
  components: {BreadcrumbBackIcon},
  props: ['links'],
  data() {
    return {
      separator: require('../assets/images/BreadcrumbsSeparator.svg'),
    };
  },
  computed:{
    ...mapGetters({
      queryParams: "searcher/getParams",
    })
  }
}
</script>

<style scoped>

</style>