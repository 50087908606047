import service from "../services/offer-service";
import router from '../../router/router'
import i18n from "../../locale";

const state = {
    list: {},
    single_offer: {},
    params:{},
    params_badge:{}
}

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_OFFER: (state, offer) => {
        state.single_offer = offer;
    },
    SET_PARAMS:(state,params) =>{
        let params_badge = {};

        if(params.body_type){
            params_badge.body_type = params.body_type;
        }
        if(params.personal){
            params_badge.personal = params.personal;
        }
        if(params.features){
            params_badge.features = params.features;
        }
        if(params.gearbox){
            params_badge.gearbox = params.gearbox;
        }
        if(params.fuel){
            params_badge.fuel = params.fuel;
        }
        if(params.drive){
            params_badge.drive = params.drive;
        }
        if(params.search){
            params_badge.search = params.search;
        }
        if (params.brand_new){
            params_badge.brand_new = 'brand_new'
        }
        if (params.used_cars){
            params_badge.used_cars = 'used_cars'
        }
        if (params.special_offer){
            params_badge.special_offer = 'special_offer'
        }
        if (params.certified){
            params_badge.certified = 'certified'
        }
        if (params.sale){
            params_badge.sale = 'sale'
        }
        if (params.invoice){
            params_badge.invoice = 'invoice'
        }
        if (params.min_price){
            params_badge.min_price = i18n.global.t('search.filter.price_from') + " " + new Intl.NumberFormat().format(params.min_price * 1000)
        }
        if (params.max_price){
            params_badge.max_price = i18n.global.t('search.filter.price_to') + " " + new Intl.NumberFormat().format(params.max_price * 1000)
        }
        if (params.min_loan){
            params_badge.min_loan = i18n.global.t('search.filter.loan_from') + " " + new Intl.NumberFormat().format(params.min_loan)
        }
        if (params.max_loan){
            params_badge.max_loan = i18n.global.t('search.filter.loan_to') + " " + new Intl.NumberFormat().format(params.max_loan)
        }
        state.params = params;
        state.params_badge = params_badge;
    }
}

const actions = {
    setList({commit,state}) {
        let params = {
            pageNo: 1, pageSize: 36
        }

        if (state.params.body_type){
            params.VehicleCategory = state.params.body_type
        }

        if (state.params.drive){
            params.DriveType = state.params.drive
        }
        if (state.params.gearbox){
            params.Transmission = state.params.gearbox
        }

        if (state.params.fuel){
            params.Fuel = state.params.fuel
        }
        if (state.params.search){
            params.SearchPhrase = state.params.search
        }
        if (state.params.sale){
            params.IsDiscounted = true;
        }
        if (state.params.invoice){
            params.CheckIsVatInvoice = true;
        }
        if (state.params.special_offer){
            params.CheckIsOfferOfTheDay = true;
        }

        if(state.params.personal === 'Yes'){
            params.CheckIsVatInvoice = true;
        }

        if(state.params.personal === 'No'){
            params.CheckIsVatInvoice = false;
        }

        if (state.params.certified){
            params.CheckIsCertified = true;
        }
        if(state.params.condition){
            params.Condition = 'Nie dotyczy'
        }
        if(state.params.brand_new && !state.params.used_cars){
            params.Condition = 'Nowy'
        }
        if(!state.params.brand_new && state.params.used_cars){
            params.Condition = 'Używany'
        }
        if (state.params.min_price){
            params.PriceFrom = state.params.min_price * 1000;
        }
        if (state.params.max_price){
            params.PriceTo = state.params.max_price * 1000;
        }

        if (state.params.sort_by){
            params.OrderBy = state.params.sort_by.split('|')[0]
            params.Order = state.params.sort_by.split('|')[1] ?? 'asc'
        }

        return service.list(params).then(({offers}) => {
            commit('SET_LIST', offers)
        })
    },
    setOffer({commit}, id) {
        return service.getOffer(id).then(({offer}) => {
            commit('SET_OFFER', offer);
        })
    },
    setParams({commit,dispatch},params){
        for (var propName in params) {
            if (params[propName] === null || params[propName] === undefined  || params[propName] === false) {
                delete params[propName];
            }
        }
        if (params['price']){
            if (params.price[0]){
                params.min_price = params.price[0]
            } else {
                delete params['min_price']
            }
            if (params['price_range']){
                if (params.price_range[1] !== params.price[1]){
                    params.max_price = params.price[1]
                }else {
                    delete params['max_price']
                }
                delete params['price_range']
            }else{
                if (params.price[1]){
                    params.max_price = params.price[1]
                }
            }

            delete params['price']
        }
        if (params['loan']){
            if (params.loan[0]){
                params.min_loan = params.loan[0]
            } else {
                delete params['min_loan']
            }

            if (params['loan_range']){
                if (params.loan_range[1] !== params.loan[1]){
                    params.max_loan = params.loan[1]
                }else {
                    delete params['max_loan']
                }
                delete params['loan_range']
            }else{
                if (params.loan[1]){
                    params.max_loan = params.loan[1]
                }
            }

            delete params['loan']
        }

        commit('SET_PARAMS',params);
        dispatch('setList');
        //console.log('setList----')
        router.push({name: "Searcher", query: params})
    },
    loadParams({commit},query){
        commit('SET_PARAMS',Object.assign({}, query))
    },
    resetParams({commit,dispatch}){
        commit('SET_PARAMS', {})
        dispatch('setList');
        router.push({name: "Searcher"})
    },
    removeParam({commit,state,dispatch},name){
        let changed_params  = Object.assign({}, state.params)
        delete changed_params[name];
        commit('SET_PARAMS',changed_params)
        dispatch('setList');
        router.push({name: "Searcher", query: changed_params})
    }

}

const getters = {
    getList: state => state.list,
    getOffer: state => state.single_offer,
    getParams: state => state.params,
    getParamsBadge: state => state.params_badge
}

const offer = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default offer;