import {createWebHistory, createRouter} from "vue-router";
// import AppLayout from "../layout/AppLayout";
import HomePage from "../pages/HomePage";
import OfferPage from "../pages/OfferPage";
import SearcherPage from "../pages/SearcherPage";

let main = {
    path: '/',
    redirect: {name:'Home'},

    children: [
        {
            path: "/home",
            name: "Home",
            component: HomePage,
        },
        {
            path: "/search",
            name: "Searcher",
            component: SearcherPage,
        },
        {
            path: "/offer/:id",
            name: "Offer",
            component: OfferPage,
        },
    ]
}


const routes = [
    main,
]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
});

// export default routes;
export default router;