<template>
  <div class="offer__cert" v-if="cert">
    <iframe class="offer__cert--frame" :src="cert"></iframe>
  </div>
  <h1>{{vin}}</h1>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "OfferCertificate",
  computed: {
    ...mapGetters({
      offer: 'offer/getOffer',
      cert: 'offer/getCert'
    }),
  },
  mounted() {
    setTimeout(() => {this.setCert(this.offer?.data?.DetailsBodyFeatures?.VIN)},1000)
  },
  methods:{
    ...mapActions({
      setCert: 'offer/setCert'
    })
  },
}
</script>

<style scoped>

</style>