<template>
  <AppLayout :color="color"></AppLayout>
</template>

<script>

import AppLayout from "./layout/AppLayout";
export default {
  name: 'App',
  components: {AppLayout},
  data(){
    return{
      color: process.env.VUE_APP_COLOR
    }
  }
}
</script>

<style>

</style>
