<template>
  <searcher-filter></searcher-filter>
  <searcher-bar></searcher-bar>
  <div class="searcher-car">
    <div class="searcher-car__wrapper">
      <router-link :to="{name:'Offer',params:{id:offer.announcementId}}" v-for="offer in list"
                   :key="offer.announcementId">
        <searcher-car :car="offer" class="last-seen__content"></searcher-car>
      </router-link>
    </div>
  </div>
  <h2 v-if="Object.keys(list).length === 0" style="padding-bottom: 9.6rem; text-align: center">No offers</h2>
  <love-it></love-it>
  <last-seen></last-seen>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SearcherCar from "../components/searcher/SearcherCar";
import LastSeen from "@/components/searcher/LastSeen.vue";
import LoveIt from "@/components/searcher/LoveIt.vue";
import SearcherFilter from "../components/searcher/SearcherFilter";
import SearcherBar from "../components/searcher/SearcherBar";


export default {
  name: "SearchPage",
  components: {SearcherBar, SearcherFilter, SearcherCar,LoveIt, LastSeen,},

  mounted() {
    this.setList()
  },
  methods: {
    ...mapActions({
      setList: 'searcher/setList'
    })
  },
  computed: {
    ...mapGetters({
      list: 'searcher/getList'
    })
  }
}
</script>

<style scoped>

</style>