<template>
  <div class="last-seen__content">
    <div class="last-seen__content__wrapper">
      <div class="header">
        <button class="like">
          <img src="/assets/img/search/last-seen/heart_full.svg">
        </button>
        <button v-if="price_before !== 0" class="sale">{{ $t('offer.similar_car.sale') }}</button>
        <img v-if="car?.images360Url" :src="car?.images360Url[0]">
        <img v-else :src=" car?.carImageSets[0]">
      </div>
      <div class="links">
        <div class="link" v-if="car.condition === 'Nowy'">
          <img src="/assets/img/offer/similar-car/menu_stars.svg">
          <a href="">Brand New</a>
        </div>
        <div class="link" v-if="car.condition === 'Używany'">
          <img src="/assets/img/offer/similar-car/menu_stars.svg">
          <a href="">Used car</a>
        </div>
        <div class="link" v-if="car.vatInvoice > 0">
          <img src="/assets/img/offer/similar-car/menu_dolar.svg">
          <a href="">Invoice</a>
        </div>
        <div class="link" v-if="car.isCertified">
          <img src="/assets/img/offer/similar-car/menu_cert.svg">
          <a href="">Certified</a>
        </div>
      </div>
      <div class="info">
        <div class="info--left">
          <div class="title">{{car?.mark}} {{car?.model}}</div>
          <div>{{car?.fuel}}</div>
          <div>{{car?.manufacturingYear}} year / {{ car?.mileage }} km</div>
        </div>
        <div class="info--right">
          <div v-if="price_before === 0" class="title" >{{ car?.price }} zł</div>
          <div v-else class="title discount">{{ car?.price }} zł <br> <span class="before-discount">{{price_before}} zł</span></div>
          <div>500 zł/mth</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearcherCar",
  props: ['car'],
  data(){
    return{
      price_before:0
    }
  },
  mounted() {
    if (this.car.priceBeforeDiscount){
      this.price_before = this.car.priceBeforeDiscount
    }
    if(this.car?.data?.Default?.PriceGrossBeforeDiscount){
      this.price_before = this.car?.data?.Default?.PriceGrossBeforeDiscount
    }
  }
}
</script>

<style scoped>

</style>