<template>
  <div class="item">
    <div class="item__wrapper">
      <div class="header" v-if="!isTitle">
        <span class="header__title header__title--dark" v-if="isDark">{{ title }}</span>
        <span class="header__title header__title--light" v-else>{{ title }}</span>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
      <div class="buttons" :class="{'buttons--center': btnCenter, 'buttons--right': btnRight}" v-if="btnMore">
        <button class="buttons__button--dark" v-if="isDark">{{ $t('buttons.view_more') }}</button>
        <button class="buttons__button--light" v-else>{{ $t('buttons.view_more') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemsSectionContainer",
  props: {
    title: String,
    isTitle: Boolean,
    btnMore: Boolean,
    isDark: Boolean,
    btnCenter: Boolean,
    btnRight: Boolean
  },
}
</script>

<style scoped>

</style>