<template>
  <div class="offer__description">
    <p class="offer__description--text">
      Pellentesque pellentesque urna nunc, ut vestibulum nisl tincidunt sed. Suspendisse vehicula nisi vel nisi lacinia suscipit. Pellentesque eget sem interdum, sollicitudin orci euismod, ullamcorper odio. Aliquam purus arcu, porta at convallis sed, accumsan eget arcu. Nullam fermentum sapien id lacus feugiat auctor. Morbi ut porta ipsum, a venenatis ex.
      Nam non bibendum odio, sit amet finibus sem. In hac habitasse platea dictumst. Mauris mattis mauris vel tristique lacinia. Duis quis odio a leo ultrices gravida. Fusce vitae mauris sollicitudin, finibus nunc eget, lacinia justo. Cras dui nibh, ultrices fermentum nulla eu, sodales blandit eros. Vestibulum justo lorem, malesuada ut odio at, maximus vestibulum neque. Phasellus mauris metus, viverra a finibus id, pharetra eget quam. Curabitur et nisi lectus. Praesent eu risus at erat dignissim maximus.
    </p>
    <button class="offer__description--more">
      {{$t('buttons.view_more')}}
    </button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OfferDescription",
  computed: {
    ...mapGetters({
      offer: 'offer/getOffer'
    }),
  },
}
</script>

<style scoped>

</style>