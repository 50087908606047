import {createStore} from "vuex";
import offer from './modules/offer-module';
import searcher from './modules/searcher-module'



const store = createStore({
    modules: {
        offer: offer,
        searcher: searcher,
    },
})

export default store