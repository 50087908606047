<template>
<div class="how-it-works-value">
  <div class="how-it-works-value_number">{{number}}</div>
  <div>{{text}}</div>
</div>
</template>

<script>
export default {
  name: "HowItWorksValueComponent",
  props: ["number", "text"]
}
</script>

<style scoped>

</style>