<template>
  <div class="home__arrivals-container" v-if="show">
    <div class="header">
      <span class="header__title header__title--light">
        Certified
      </span>
    </div>
    <carousel :settings="settings" class="carousel home__arrivals" :breakpoints="breakpoints">
      <slide v-for="slide in getSlides" :key="slide" :href="slide">
        <router-link :to="{name:'Offer',params:{id:slide?.announcementId}}">
          <searcher-car :car="slide"></searcher-car>
        </router-link>
      </slide>
      <template #addons>
        <navigation/>
      </template>
    </carousel>
    <div class="home__arrivals--mobile" v-for="slide in getSlides" :key="slide" :href="slide">
      <router-link :to="{name:'Offer',params:{id:slide?.announcementId}}" style="width: 100%">
        <div class="love-it__content home__arrivals-content">
          <div class="love-it__content__wrapper home__best-box home__arrivals-box">
            <div class="home__best-data">
              <div class="home__best--name">{{ slide?.model }}</div>
              <div class="home__best--price">{{ slide?.price }} zł</div>
              <div class="home__best--old-price" v-if="slide?.isDiscounted == true">{{ slide?.priceBeforeDiscount }}
                zł
              </div>
            </div>
            <div class="photo">
              <img :src="slide?.carImageSets[0]">
            </div>
            <div class="info">
              <div class="info__box">
                <div class="title">Power</div>
                <div>{{ slide?.data?.Main?.TYPHP }} PS</div>
              </div>
              <div class="info__box">
                <div class="title">Engine</div>
                <div>{{ slide?.data?.DetailsMain?.TYPCapTech }} cm<sup>3</sup></div>
              </div>
              <div class="info__box">
                <div class="title">Year</div>
                <div>{{ slide.manufacturingYear }}</div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {Carousel, Navigation, Slide} from "vue3-carousel";
import {mapActions, mapGetters} from "vuex";
import SearcherCar from "../searcher/SearcherCar";

export default {
  name: "HomeNewArrivals",
  components: {
    SearcherCar,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      show: false,
      offer_of_the_day: require('../../assets/images/home/Offer.png'),
      settings: {
        itemsToShow: 1,
        wrapAround: false,
        snapAlign: "start",
        // wrapAround: true,
      },
      breakpoints: {
        768: {
          itemsToShow: 2,
        },
        1280: {
          itemsToShow: 3,
        },
      }
    }
  },
  mounted() {
    this.setSlides();
    setTimeout(() => {
      this.show = true;
    }, 750);
  },
  methods: {
    ...mapActions({
      setSlides: "offer/setNewArrivalsForYou"
    })
  },
  computed: {
    ...mapGetters({
      getSlides: "offer/getNewArrivalsForYou"
    }),
    getSlides2: function () {
      return [
        {
          name: 'LR Velar',
          img_left: "/assets/demo/img/love-it/velar_left.png",
          img_front: "/assets/demo/img/love-it/velar_front.png",
          img_right: "/assets/demo/img/love-it/velar_right.png",
          img:"/assets/demo/img/love-it/eqs.png",
          power: "405 kW/550 PS",
          engine: "5.0 V8",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'Renault Logan',
          img_left: "/assets/demo/img/love-it/logan_left.png",
          img_front: "/assets/demo/img/love-it/logan_front.png",
          img_right: "/assets/demo/img/love-it/logan_right.png",
          img:"/assets/demo/img/love-it/eqs.png",
          power: "55 kW/75 PS",
          engine: "1.2",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'Mazda CX-5',
          img_left: "/assets/demo/img/love-it/cx_5_left.png",
          img_front: "/assets/demo/img/love-it/cx_5.png",
          img_right: "/assets/demo/img/love-it/cx_5_right.png",
          img: "/assets/demo/img/love-it/cx_5.png",
          power: "360 kW/500 PS",
          engine: "3.0 TFSI",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'Renault Talisman',
          img_left: "/assets/demo/img/love-it/talisman_left.png",
          img_front: "/assets/demo/img/love-it/talisman_front.png",
          img_right: "/assets/demo/img/love-it/talisman_right.png",
          img:  "/assets/demo/img/love-it/g_class.png",
          power: "90 kW/122 PS",
          engine: "1.5 TCe",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'BMW X3',
          img_left: "/assets/demo/img/love-it/x3_left.png",
          img_front: "/assets/demo/img/love-it/x3_front.png",
          img_right: "/assets/demo/img/love-it/x3_right.png",
          img:  "/assets/demo/img/love-it/g_class.png",
          power: "225 kW/306 PS",
          engine: "3.0 35i",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'Mercedes E Class',
          img_left: "/assets/demo/img/love-it/e_class_left.png",
          img_front: "/assets/demo/img/love-it/e_class_front.png",
          img_right: "/assets/demo/img/love-it/e_class_right.png",
          img: "/assets/demo/img/love-it/g_class.png",
          power: "360 kW/500 PS",
          engine: "3.0 TFSI",
          year: "2022",
          price: 31000,
          old_price: 35000
        },
        {
          name: 'LR Velar',
          img_left: "/assets/demo/img/love-it/velar_left.png",
          img_front: "/assets/demo/img/love-it/velar_front.png",
          img_right: "/assets/demo/img/love-it/velar_right.png",
          img:"/assets/demo/img/love-it/eqs.png",
          power: "405 kW/550 PS",
          engine: "5.0 V8",
          year: "2022",
          price: 31000,
          old_price: 35000
        }
      ]
    },
  },

}
</script>

<style scoped>

</style>