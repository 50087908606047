<template>
  <div class="find-your-dealer__container">
    <div class="find-your-dealer__wrapper">
      <div class="find-your-dealer__form">
        <div class="find-your-dealer__form__title">{{ $t('home.find_your_dealer.title') }}</div>
        <form class="find-your-dealer__form">
          <dv class="input_container">
            <i class="icons icon-search"></i><input type="text" class="input__text" :value="$t('home.find_your_dealer.search')">
          </dv>
          <ul class="find-your-dealer__form__ul">
            <li class="find-your-dealer__form__li"><i class="icons icon-approve"></i>{{ $t('home.find_your_dealer.checkboxes.box_1') }}</li>
            <li class="find-your-dealer__form__li"><i class="icons icon-approve"></i>{{ $t('home.find_your_dealer.checkboxes.box_2') }}</li>
            <li class="find-your-dealer__form__li"><i class="icons icon-approve"></i>{{ $t('home.find_your_dealer.checkboxes.box_3') }}</li>
            <li class="find-your-dealer__form__li"><i class="icons icon-approve"></i>{{ $t('home.find_your_dealer.checkboxes.box_4') }}</li>
          </ul>
          <button>Search</button>
        </form>
      </div>
      <div class="find-your-dealer__map">
        <img src="assets/img/home/home_map.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindYourDealerComponent"
}
</script>

<style scoped>

</style>